@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GothamMedium";
  src: local("GothamMedium"),
   url("./fonts/Gotham-Medium.otf") format("opentype");
 }

 @font-face {
  font-family: "Baron";
  src: local("Baron"),
   url("./fonts/Baron.otf") format("opentype");
 }

.font-link {
  font-family: 'Raleway', sans-serif;
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Gothic', 'GothamMedium'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  color: #E0FBFC;
  font-family: "Baron";
}

.navbarText {
  font-family: "GothamMedium";
  color: #E0FBFC; 
  font-size: 22;
  margin-left: 3rem;
}

.corner {
  position: fixed;
  rotate: 180deg;
  left: 5rem;
  top: -1rem;
  width: 100px;
  height: 100px;
  z-index: -1;
  background: #101010;
  -webkit-mask-image: radial-gradient(circle 10px at 0 0, transparent 0, transparent 20px, black 21px);
}

.sidenav {
  height: 100%; 
  width: 160px; 
  position: fixed; 
  z-index: 1; 
  top: 0; 
  left: 0;
  background-color: #101010; 
  overflow-x: hidden; 
  padding-top: 70px;
}

.sidenav.hide {
  transition: all 0.2s ease-in-out;
  height: 0;
  overflow: hidden;
}

/* The navigation menu links */
.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Style page content */
.main {
  margin-left: 160px; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

.sidebarBack {
  position: fixed;
  bottom: 1rem;
  left: 7.5rem;
  z-index: 1;
  width: 1.7rem;
  height: 1.7rem;
}

.sidebarBackAlt {
  position: fixed;
  bottom: 1rem;
  left: 7.5rem;
  z-index: 1;
  width: 1.7rem;
  height: 1.7rem;
  justify-content: center;
  background-color: #101010;
  border-radius: 5px;
}

.userPopdownSettings {
  color: gray;
}

.userPopdownSettings :hover {
  color: white;
}

.userPopdownLogoutA {
  text-decoration: none;
  color: #dc3545;
}

.userPopdownLogoutB {
  text-decoration: none;
  color: #000000;
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}